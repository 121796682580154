<template>
	<div :style="{fontFamily: fontFamily + ' !important'}">
		<div :class="`sf-cnt-${type}`" :style="{backgroundColor: isPopup ? settings.popup.bgColor : ''}" >
			<div class="sf-cnt-inner" :class="{'positionRelative' : isPopup}">
				<span v-if="isPopup" class="closePopup" @click="closePopupOnClick">&times;</span>

				<div v-if="isLanding" class="sf-cnt-header">
					<div class="sf-cnt-header__logo">
						<img v-if="settings.header.logo" :src="settings.header.logo" alt="logo">
					</div>
					<div class="sf-cnt-header__contact">
						<p v-if="settings.header.companyName">Prepared by: <span>{{settings.header.companyName}}</span></p>
						<p><a :href="`tel:${settings.header.companyPhone}`">{{settings.header.companyPhone}}</a></p>
						<p><a class="blue-link" :href="`mailto:${settings.header.companyEmail}`">{{settings.header.companyEmail}}</a>
						</p>
					</div>
				</div>
				<div class="sf-cnt-form" :class="[{ withMargin: settings.margin}, alignmentClass]">

					<form @submit="submit" ref="form">
						<div class="sf-cnt-form__title" :style="{ color: settings.titleColor}"
							v-html="settings.title"></div>
						<p class="sf-cnt-form__descr" v-html="settings.description"></p>
						<p v-if="error" class="sf-cnt-form__descr" style="color: red; font-size: 16px">{{error}}</p>
						<div class="sf-cnt-form-fields">

							<div class="form-group">
								<input class="form-control" type="text" :placeholder="settings.url" v-model="formData.websiteUrl" required="required" >
							</div>
							<div class="form-group" v-if="settings.emailField.view">
								<input class="form-control" type="text" :placeholder="settings.emailField.email"
									   :required="settings.emailField.required" v-model="formData.email">
							</div>

							<div class="form-group" v-if="settings.otherFields.Name">
								<input class="form-control" type="text" :placeholder="settings.otherFields.Name.value"
									   :required="settings.otherFields.Name.required" v-model="formData.firstName">
							</div>

							<div class="form-group" v-if="settings.otherFields.Phone">
								<input class="form-control" type="text" :placeholder="settings.otherFields.Phone.value"
									   :required="settings.otherFields.Phone.required" v-model="formData.phone">
							</div>

							<div class="form-group" v-if="settings.otherFields.Company">
								<input class="form-control" type="text"
									   :placeholder="settings.otherFields.Company.value"
									   :required="settings.otherFields.Company.required" v-model="formData.company">
							</div>


						</div>


						<div class="flexCenter">

							<div class="custom-gdpr-group" v-if="settings.isGdpr">
								<input id="gdpr-check" type="checkbox" class="custom-gdpr-input"
									   v-model="formData.isGdprChecked">
								<label for="gdpr-check" class="custom-gdpr-label">
									<span>{{settings.GdprMessage}}</span></label>
							</div>
						</div>

						<div class="flexCenter">

							<button class="btn alignCenter"
									:style="{ color: settings.buttonTextColor, backgroundColor: settings.buttonColor, borderColor: settings.buttonColor}"
									type="submit" v-html="settings.buttonText">
							</button>

						</div>
						<div class="flexCenter">
							<p v-if="settings.show_powered_by" class="sf-subtitle">Powered by: <a href="https://siteauditor.com">SiteAuditor.com</a></p>
						</div>


						<h2 v-if="message" class="flexCenter"
							:style="{'margin-top': '20px', color: settings.titleColor}">{{message}}</h2>

					</form>


				</div>
			</div>
		</div>
	</div>
</template>

<script>
    import {FONT_NAMES, FORMAT_TYPES} from "../constants";

    export default {
        name: "Form",
        props: {
            type: {
                type: String,
                required: true
            },
            settings: {
                type: Object,
                required: true
            },
			error: {
                type: String,
				required: false,
				default: ''
			},
			message: {
                type: String,
				required: false,
				default: ''
			},
            closePopupOnClick: {
                type: Function,
                required: false,
                default: () => {}
            },
        },
		data() {
            return {
                formData: {
                    websiteUrl: null,
					email: null,
                    phone: null,
                    country: null,
                    firstName: null,
                    isGdprChecked: false
                }
			}
		},
		mounted() {
            console.log(this.settings.show_powered_by);
        },
        methods: {
            submit(event) {
                event.preventDefault();
                this.$emit('submit', this.formData);
            }
        },
        computed: {
            alignmentClass() {
                return 'sf-cnt-form--' + this.settings.alignment.toLowerCase();

            },
			fontFamily() {
              return this.settings.font === FONT_NAMES.OPEN_SANS ? 'sans-serif' : this.settings.font;
			},
            isEmbed() {
                return this.type === FORMAT_TYPES.EMBED;
            },
            isPopup() {
                return this.type === FORMAT_TYPES.POPUP;
            },
            isLanding() {
                return this.type === FORMAT_TYPES.LANDING_PAGE;
            },
        }
    }
</script>

<style lang="scss">
	@import "../assets/styles/blocks/form";
</style>

<style scoped>
	.withMargin {
		border: 1px solid #d2d6de;
		border-radius: 5px;
		padding: 20px 15px;

	}
	.sf-cnt-header__logo {
		max-width: 300px;
		padding-top: 14px;
	}
	.custom-checkbox  {
		margin-top: 20px;
	}
	.custom-checkbox .custom-control-input {
		display: block;
		margin-top: 2px;
	}

	button:hover {
		filter: brightness(90%)
	}

	.positionRelative {
		position: relative;
	}

	.closePopup {
		position: absolute;
		z-index: 4;
		top: 0;
		right: 12px;
		color: #ccd2db;
		opacity: 1;
		font-size: 28px;
		font-weight: 700;
		cursor: pointer;
	}

	.closePopup:hover,
	.closePopup:focus {
		color: #ccd2db;
		text-decoration: none;
		cursor: pointer;
	}

	p {
		margin-top: 10px;
	}
	.flexCenter {
		display: flex;
		justify-content: center;
	}

	.alignCenter {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.sf-subtitle {
		font-size: 14px;
		line-height: 16px;
		color: #828F9C;;
	}


	.sf-subtitle a:hover {
		color: #747F85;
	}


	.sf-subtitle a {
		text-decoration: none;
	}
</style>
