import {FONT_NAMES, FORMAT_TYPES} from "../constants";

export default {
    reformatData(form, data, type, popupDefaults = null, fieldArrayDefaultLanding = null, headerDefault = null) {
        let settings = data.settings;
        let reportSettings = data.report_manager_settings ;

        let css = settings.css;

        let title = settings.title;

        form.show_powered_by = reportSettings && Object.prototype.hasOwnProperty.call(reportSettings, 'show_powered_by') ? reportSettings.show_powered_by : 1;

        form.font = css.inputFontfamily ? css.inputFontfamily : FONT_NAMES.OPEN_SANS;
        form.alignment = title.formalignment ? title.formalignment : (css.formalignment ? css.formalignment : 'HORIZONTAL');

        if (form.alignment === 'ALLHORIZONTAL') {
            form.alignment = 'HORIZONTAL'
        }

        if (type !== FORMAT_TYPES.LANDING_PAGE) {
            form.margin = settings.is_margin || 0;
        }

        form.emailField = {
            email: 'Email',
            required: true,
            view: true
        };

        if (css.emailField) {
            form.emailField.email = css.emailField.email;
        }

        if (type === FORMAT_TYPES.POPUP) {

            form.popup = {
                ...popupDefaults,
                ...settings.popup,
            };

            if (Object.prototype.hasOwnProperty.call(settings.popup,  'urlCheck')) {
                form.popup.urlCheck = settings.popup.urlCheck;

            } else if (!Object.prototype.hasOwnProperty.call(settings.popup,  'urlCheckType') || (Object.prototype.hasOwnProperty.call(settings.popup,  'urlCheckType') && !settings.popup.urlCheckType) ) {
                form.popup.urlCheck = 0;
            }

            form.popup.popupLimit = form.popup.popupLimit | 0;

            form.emailField.email = title.email;

        } else if (type === FORMAT_TYPES.LANDING_PAGE) {

            form.emailField = fieldArrayDefaultLanding.Email;

            let defaultHeader = reportSettings && reportSettings.logo_path !== 'https://siteauditor.s3.amazonaws.com/app_report_logo.svg'? {
                companyName: reportSettings.company_name ? reportSettings.company_name : '',
                companyEmail: reportSettings.company_email ? reportSettings.company_email : '',
                companyPhone: reportSettings.company_phone ? reportSettings.company_phone : '',
                logo: reportSettings.logo_path ? reportSettings.logo_path : null
            } : headerDefault;

            form.header = css.header ? JSON.parse(JSON.stringify(css.header)) : defaultHeader;

            if (css.header && form.header.logo && (form.header.logo.startsWith("http://") || form.header.logo.startsWith("https://"))) {
                form.header.logo = form.header.logo + `?` + Date.now();
            }
        }

        form.description = settings.description ?? title.description ?? '';

        form.title = (type === FORMAT_TYPES.LANDING_PAGE) ? title : title.title;

        form.titleColor = css.titleColor ? css.titleColor : '#000000';

        form.backgroundColor = css.backgroundColor ? css.backgroundColor : '#FFFFFF';

        let fieldArray = css.fieldArray ? JSON.parse(JSON.stringify(css.fieldArray)) : (css.selectedValues ? JSON.parse(JSON.stringify(css.selectedValues)) : []);

        form.url = (type !== FORMAT_TYPES.LANDING_PAGE) ? title.url : 'URL';


        const iterator = fieldArray.values();

        for (const item of iterator) {
            switch (item.label) {
                case 'NAME':
                    form.otherFields.Name = item;
                    break;
                case 'PHONE':
                    form.otherFields.Phone = item;
                    break;
                case 'COMPANY':
                    form.otherFields.Company = item;
                    break;
                case 'URL':
                    form.url = item.value;
                    break;
                case 'EMAIL':
                    form.emailField = item;
                    form.emailField.email = item.value;
                    break;

            }
        }

        form.buttonTextColor = css.buttonTextColor;
        form.buttonColor = css.buttonColor;
        form.buttonText = title.buttonText ?? settings.button_text;
        form.isGdpr = settings.is_gdpr;
        form.GdprMessage = settings.gdpr_message;

        return form;

    }
}
