<template>
	<div class="container">
		<div class="sf-main">
			<div class="sf-cnt">
				<Form v-if="dataLoaded" :settings="form" :type="type" @submit="createLead" :error="error" :message="message"></Form>
			</div>
		</div>
	</div>

</template>

<script>
    import Form from "../../../shared/components/Form";
    import {FORMAT_TYPES, LEAD_TYPES} from "../../../shared/constants";
    import {formSubmitMixin} from "../../../shared/mixins/form-submit-mixin";
    import {conversionTrackingMixin} from "../../../shared/mixins/conversion-tracking-mixin";
    import formHelper from "../../../shared/services/setupFormHelper";

    export default {
        name: "Landing",
        components: {Form},
        mixins: [formSubmitMixin, conversionTrackingMixin],
        data() {
            return {
                domain: null,
                fieldArrayDefaultLanding: {
                    Email: {
                        label: 'EMAIL',
                        email: 'EMAIL',
                        value: 'EMAIL',
                        sequence: 'EM',
                        view: true,
                        required: true,
                        color: '#757575'
                    },
                    Url: {
                        label: 'URL',
                        value: 'URL',
                        sequence: 'U',
                        required: true,
                        color: '#757575'
                    },
                    Name: {
                        label: 'NAME',
                        value: 'NAME',
                        sequence: 'NAM',
                        view: true,
                        required: false,
                        color: '#757575'
                    },
                    Phone: {
                        label: 'PHONE',
                        value: 'PHONE',
                        sequence: 'PHON',
                        view: true,
                        required: false,
                        color: '#757575'
                    },
                    Company: {
                        label: 'COMPANY',
                        value: 'COMPANY',
                        sequence: 'COMPA',
                        view: true,
                        required: false,
                        color: '#757575'
                    }


                },
                dataLoaded: false,
                type: FORMAT_TYPES.LANDING_PAGE,
                headerDefault: {
                    companyName: 'Your company',
                    companyEmail: 'company@email.com',
                    companyPhone: '+1 987-654-3210',
                    logo: 'https://siteauditor.s3.amazonaws.com/v2_logos/main/sa_logo.svg'
                },
                reportSetting: null,
                form: {
                    show_powered_by: 1,
                    popup: {},
                    alignment: null,
                    font: 'Open Sans',
                    margin: false,
                    title: '',
                    description: '',
                    titleColor: '',
                    backgroundColor: '',
                    url: 'URL',
                    header: {
                        companyName: '',
                        companyEmail: '',
                        companyPhone: '',
                        logo: null
                    },
                    emailField: {
                        email: 'Email',
                        required: true,
                        view: true
                    },
                    otherFields: {
                        Name: null,
                        Phone: null,
                        Company: null
                    },

                    formFields: {},
                    buttonColor: '',
                    buttonTextColor: '',
                    buttonText: '',

                    isGdpr: false,
                    GdprMessage: ''
                },
            }
        },
        created() {
            this.$store.dispatch('landing/getDomain', {url: window.location.href})
                .then((response) => {
                    this.domain = response.domain;
                    this.getSettings(response.domain);
                })
                .catch(() => {
                    window.location.href = 'https://' + process.env.VUE_APP_DOMAIN;
                });

        },
        methods: {
            getSettings(domain) {
                this.$store.dispatch('landing/getLandingSettings', {domain: domain}).then((response) => {

                    this.reportSetting = response.report_manager_settings;
                    this.settings = response.settings;

                    if (this.reportSetting && this.reportSetting.view_analytics) {

                        this.setConversionScripts(this.reportSetting.analytics_script);
                    }

                    this.form = formHelper.reformatData(
                        this.form,
                        response,
                        this.type,
                        null,
                        this.fieldArrayDefaultLanding,
                        this.headerDefault);


                    document.querySelector("body").style.backgroundColor = this.form.backgroundColor;

                    // accessing the link tag

					if (response.report_manager_settings && response.report_manager_settings.favicon_path) {
                        const favicon = document.getElementById("favicon");
                        favicon.href = response.report_manager_settings.favicon_path;
					}

                    this.dataLoaded = true;
                }).catch((error) => {
                    this.dataLoaded = true;
                    this.showErrorNotifications(error);
                });
            },
            createLead(form) {
                form.leadType = LEAD_TYPES.LANDING_PAGE;
                form.domainName = this.domain;

                if( this.form.isGdpr && !form.isGdprChecked) {
                    this.error = 'Please accept the agreement';
                    return;
                }

                this.submitForm(this.type, form, this.reportSetting);
            }
        }


    }
</script>

