import {FORMAT_TYPES, VIEW_REPORT} from "../constants";
import {check_url_existence_mixin} from "./check-url-existence-mixin";

export const formSubmitMixin = {
    data() {
        return {
            message: null,
            error: null
        }
    },
    mixins: [check_url_existence_mixin],
    methods: {
        submitForm(type, form, reportSettings) {

            form.websiteUrl = this.correctUrl(form.websiteUrl);

            this.message = null;
            this.error = null;
            let endpoint = type + '/createLead';
            this.$store.dispatch(endpoint, form)
                .then((response) => {
                    if (response && response.token) {

                        if (reportSettings && Object.prototype.hasOwnProperty.call(reportSettings, 'view_report') && reportSettings.view_report === VIEW_REPORT.DONT_DISPLAY) {
                            this.message = reportSettings.index_response_message;

                            if (reportSettings.redirect_url) {
                                setTimeout(function () {
                                    window.location = reportSettings.redirect_url;
                                }, reportSettings.redirect_url_time * 1000);

                            }

                        } else if (response.url) {
                            let url = response.url + `/report?token=${response.token}`;
                            let target = '_self';
                            if(type === FORMAT_TYPES.EMBED || type === FORMAT_TYPES.POPUP) {
                                target = '_blank'
                            }
                            window.open(url, target);
                        }
                    }
                })
                .catch((error) => {
                    this.showError(error);
                });
        },
        showError(error) {
            if (!error.response) {
                this.error = "Something went wrong please try again";
                return;
            }
            if (error.response.status === 404) {
                this.error =  'Not Found';
            } else if (error.response.status === 400) {
                this.error = error.response.data[Object.keys(error.response.data)[0]];

            } else if (error.response.status === 409) {
                this.error = error.response.data;
            } else if (error.response.status === 422) {
                let messages = [];
                const errorResult = error.response.data.errors;
                for (const key in errorResult) {
                    if (Object.prototype.hasOwnProperty.call(errorResult, key)) {
                        messages.push(errorResult[key].join(', '));
                    }
                }
                this.error = messages.toString();

            } else if (error.response.status === 403) {

                this.error = error.response.data;
            } else {
                this.error = 'Something went wrong please try again';
            }
        },
    }
};
